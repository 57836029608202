import Vue from "vue";
import TabTotal from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/Total/index.vue";
import TabSocioDemographic from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/SocioDemographic/index.vue";
import TabPoisInformation from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/PoisInformation/index.vue";
import TabDate from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/Date/index.vue";
import TabResidencePlace from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/ResidencePlace/index.vue";
import TabDigitalBehavior from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/DigitalBehavior/index.vue";
import TabConnectionType from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/ConnectionType/index.vue";
import TabPoisAndMobility from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/PoisAndMobility/index.vue";
import TabGenderAndAge from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/GenderAndAge/index.vue";
import { mapActions, mapGetters } from "vuex";
import { TypeLoading } from "@/interfaces/loading";

export interface IFilterData {
	type: string;
	name: string;
}

export default Vue.extend({
	name: "PersonsAudience",
	props: {},
	components: {
		TabTotal,
		TabSocioDemographic,
		TabPoisInformation,
		TabDate,
		TabResidencePlace,
		TabDigitalBehavior,
		TabConnectionType,
		TabPoisAndMobility,
		TabGenderAndAge,
	},
	data: () => ({
		currentTab: 1,
		filters: [] as IFilterData[],
		savedFilters: [] as IFilterData[],
		formattedData: {},
		panel: [0, 1, 2, 3, 4, 5, 6, 7, 8],
		modal: false,
	}),
	created() {},
	mounted() {
		this.$nextTick(async () => {
			this.setLoadingData(TypeLoading.loading);
			await this.initialize();
			this.setLoadingData();
			this.fetchAll();
		});
	},
	computed: {
		...mapGetters("audience", {
			filterHasChanges: "filterHasChanges",
		}),
	},
	methods: {
		...mapActions("audience", ["initialize", "fetchAll", "fetchFilters"]),
		...mapActions("loading", ["setLoadingData"]),

		handleEditPoi() {
			this.modal = true;
		},

		handleYes() {
			// this.$router.push({
			// 	name: "PersonsIndex",
			// });
			this.modal = false;
			this.$emit("change-tab", 0);
		},

		handleNo() {
			this.modal = false;
		},

		handleClickFilter() {
			this.fetchFilters();
		},

		deleteFilter(filter) {
			const filterIndex = this.filters.findIndex(
				(item) => item == filter
			);
			this.filters.splice(filterIndex, 1);
		},
	},
	watch: {},
});
