import Vue from "vue";
import SelectCountry from "@/views/Admin/Persons/V10/Dependencies/Select/Country/index.vue";
import SelectAudienceType from "@/views/Admin/Persons/V10/Dependencies/Select/AudienceType/index.vue";
import SelectGeo from "@/views/Admin/Persons/V10/Dependencies/Select/Geo/index.vue";
import SelectPois from "@/views/Admin/Persons/V10/Dependencies/Select/Pois/index.vue";
import SelectPrivate from "@/views/Admin/Persons/V10/Dependencies/Select/Private/index.vue";
import CardCollapsePanel from "@/views/Admin/Persons/V10/Dependencies/Panels/CardCollapsePanel/index.vue";
import CartoPois from "@/views/Admin/Persons/V10/Dependencies/Carto/Pois/index.vue";
import GraphicContent from "@/views/Admin/Persons/V10/Dependencies/Common/Graphic/Content/index.vue";
import AudienceExport from "@/views/Admin/Persons/V10/Dependencies/Dialogs/ExportAudience/index.vue";
import SavePois from "@/views/Admin/Persons/V10/Dependencies/Dialogs/SavePois/index.vue";
import GeoFencing from "@/views/Admin/Persons/V10/Dependencies/Dialogs/GeoFencing/index.vue";
import { getExpansionPane } from "@/views/Admin/Persons/V10/Dependencies/Utils";
import { Notification } from "@/interfaces/proccess";
import { isEmpty, isEqual, isUndefined } from "lodash";
import { mapActions, mapGetters } from "vuex";
import {
	ActivePanelTab,
	AnalizeType,
	PersonKey,
} from "@/interfaces/persons/v10/types";
import CardAction from "@/views/Admin/Persons/V10/Dependencies/Common/Buttons/CardAction/index.vue";
import CardGrouped from "@/views/Admin/Persons/V10/Dependencies/Amcharts/Cards/Grouped/index.vue";
import { ActionKeyof } from "@/interfaces/persons/v10/route";
import { ResultContinent, ResultData } from "@/interfaces/persons/v10/response";
import {
	ElementData,
	GraphicData,
	StoreAttribution,
} from "@/interfaces/persons/v10/person";
import SnackMessage from "@/components/Commons/Loaders/SnackMessage/index.vue";
import { ItemGraphic } from "@/interfaces/graphic";
import { convLocaleString } from "@/utils/convert";
import { graphicDataItems } from "./utils";
import { ButtonActionEntity } from "@/models/persons/v10/Tabs/Pois/ButtonAction";
import AppStoreAttribution from "@/views/Admin/Persons/V10/Dependencies/Dialogs/StoreAttribution/index.vue";
import { ConfigPostEntity } from "@/models/persons/v10/response";
import notificationService from "@/services/notification-service";
import store from "@/store";
import { ListOptionEntity } from "@/models/persons/v10/Implements";
import {
	audienceTypeIDDefault,
	countryDefault,
} from "@/models/persons/v10/Person";

export default Vue.extend({
	name: "TabPois",
	props: {},
	components: {
		CardCollapsePanel,
		SelectCountry,
		SelectAudienceType,
		SelectGeo,
		SelectPois,
		SelectPrivate,
		CardAction,
		CartoPois,
		GraphicContent,
		SnackMessage,
		CardGrouped,
		AppStoreAttribution,
		AudienceExport,
		SavePois,
		GeoFencing,
	},
	data: () => ({
		itemsExpansion: [] as any[],
		activePanel: undefined,
		message: "",
		snack_color: undefined,
		isGettingFromStored: true,
	}),
	created() {
		this.$nextTick(async () => {
			await this.initializeData();
		});
	},
	mounted() {},
	destroyed() {
		// this.resetPerson({ country: this.getPerson.getCountry() });
		// this.initPoisActions();
	},
	computed: {
		...mapGetters("person", [
			"getPerson",
			"getLikeAll",
			"getResponseData",
			"getGraphicResponse",
			"getQueryParams",
			"getPoisActions",
			"getStoreAttribution",
			"getPersistent",
			"getCountry",
			"getCampaigns",
			"getResource",
			"isBackFromAudience",
		]),

		...mapGetters("campaign", ["getCampaignList"]),
		...mapGetters("profile", ["account"]),
		...mapGetters("audience", ["getPoisCount"]),

		getGraphicDataGeo() {
			return graphicDataItems.geo;
		},

		getGraphicDataPois() {
			return graphicDataItems.pois;
		},

		getGraphicDataItems() {
			return graphicDataItems;
		},

		isAnalizedGeo() {
			return this.getPerson.isAnalizedGeo();
		},

		isAnalizedPois() {
			return this.getPerson.isAnalizedPois();
		},

		enabledGraphicPois() {
			return this.getPerson.enabledGraphicPois();
		},

		/**
		 * Detect type reach by audience type
		 */
		getTypeReach() {
			return this.getPerson.isTypeAudiencePois() ? "pois" : "geo";
		},

		getCountPois() {
			const total: number = this.getPoisCount.getTotal();
			return [{ count: convLocaleString(total) }];
		},

		getCountReach() {
			return this.getCountReachItems(this.getTypeReach);
		},

		showCountPois() {
			return this.getPoisCount.isFetched();
		},

		showCountReach() {
			const total = this.getCountReachItems(this.getTypeReach);
			return !isEmpty(total);
		},
	},
	methods: {
		...mapActions("person", [
			"resetPerson",
			"fetchGetGraphicData",
			"fetchAnalizeAudience",
			"setResultData",
			"postData",
			"fetchCalculateReach",
			"initPoisActions",
			"fetchStoreAttribution",
			"fetchData",
			"setResource",
			"savePois",
			"saveGeoFencing",
			"saveDataToStorage",
			"getSavedDataToStorage",
			"clearDataToStorage",
		]),
		...mapActions("audience", {
			clearAudienceFilters: "clearFilters",
			fetchPoisCount: "fetchPois",
			exportAudience: "exportAudience",
		}),

		...mapActions("campaign", ["getStoreAttributions"]),

		async initializeData() {

			this.isGettingFromStored = true;

			this.getPoisCount.resetFetch();

			const isBack: Boolean = this.isBackFromAudience;

			const { person, resources, items, pois_actions } =
				await this.getSavedDataToStorage();

			const valid: Boolean =
				!isUndefined(person) &&
				!isUndefined(resources) &&
				!isUndefined(items) &&
				!isUndefined(pois_actions);
				
			if (isBack && valid) {
				this.itemsExpansion = items;				
				this.isGettingFromStored = false;
				await this.fetchPoisCount();
				this.handleSubmitCarto();
				return;
			}			

			if (isBack) {
				this.removeParamsFromURL();
			}

			this.itemsExpansion = await getExpansionPane();

			await this.fetchCountries();
			await this.setCountry();
			await this.setDefaultAudienceType();

			this.isGettingFromStored = false;
		},

		fetchCountries() {
			return new Promise(async (resolve) => {
				this.getPoisCount.resetFetch();

				// Fetch all countries
				const result: ResultContinent[] = await this.fetchData({
					type: "countries",
				});

				await this.setResource({
					key: "countries",
					value: result,
				});

				resolve(result);
			});
		},

		setCountry() {
			return new Promise(async (resolve) => {
				// Fetch all countries
				const result: ResultContinent[] = this.getResource({
					key: "countries",
				});

				// flat countries result
				const flatCountries: ElementData[] = result
					.map((r) => r.items)
					.flat();

				// destructuring country from account
				const { numeric_code, name } = this.account.country;

				// has country account -> flatCountries
				const hasCountry: Boolean = !!flatCountries.find(
					(c) => c.id == numeric_code
				)?.id;

				const countryGlobal: ElementData = hasCountry
					? { id: parseInt(numeric_code), value: name }
					: countryDefault;

				await this.getPerson.setByKey(
					PersonKey.COUNTRY_GLOBAL,
					countryGlobal
				);

				this.getQueryParams.setCountryCode(countryGlobal.id);

				resolve(countryGlobal);
			});
		},

		async setDefaultAudienceType() {
			const result: ListOptionEntity[] = await this.fetchData({
				type: "audiente_type",
			});

			await this.setResource({
				key: PersonKey.AUDIENCE_TYPE,
				value: result,
			});

			const typePoisDefault = result.find(
				(r) => r.id === audienceTypeIDDefault
			);

			this.getPerson.setByKey(PersonKey.AUDIENCE_TYPE, typePoisDefault);
		},

		getGraphicSource(type: "geo" | "pois", key: string) {
			return (this.getGraphicDataFromStore(type, key) as GraphicData)?.data || [];
		},

		isLoadingSource(type: "geo" | "pois", key: string) {
			return !!(this.getGraphicDataFromStore(type, key) as GraphicData)?.loading;
		},

		getCountReachItems(type: "geo" | "pois") {
			const items: ItemGraphic[] = (
				this.getGraphicDataFromStore("reach", type) as GraphicData
			)?.data || [];

			if (isEmpty(items)) return [];

			const total = items.map((i) => ({
				count: `${i.name}: ${convLocaleString(i.uniques)}`,
			}));
			return total;
		},

		getCount(key: string) {
			switch (key) {
				case "analize_pois":
					return this.getCountPois;
				case "calculate_reach":
					return this.getCountReach;
				case "store_attribution":
					return this.getPerson.store_attribution.getMaidUnique();
				default:
					return [];
			}
		},

		getChipColor(key: string) {
			switch (key) {
				case "analize_pois":
					return this.getPoisCount.isValid() ? "indigo" : "red";
				default:
					return "indigo";
			}
		},

		showReach(key: string) {
			switch (key) {
				case "analize_pois":
					return this.showPoisCount(key);
				case "calculate_reach":
					return this.showCalculateReach(key);
				case "store_attribution":
					return this.getPerson.store_attribution.showMaidUnique();
				default:
					return false;
			}
		},

		showPoisCount(key: string) {
			return key === "analize_pois" && this.showCountPois;
		},

		showCalculateReach(key: string): Boolean {
			return key === "calculate_reach" && this.showCountReach;
		},

		isCountry(type: PersonKey) {
			return PersonKey.COUNTRY_GLOBAL === type;
		},

		isAudienceType(type: PersonKey) {
			return PersonKey.AUDIENCE_TYPE === type;
		},

		isGeo(type: PersonKey) {
			return PersonKey.GEO === type;
		},

		isPois(type: PersonKey) {
			return PersonKey.POIS === type;
		},

		isPrivate(type: PersonKey) {
			return PersonKey.PRIVATE === type;
		},

		getGraphicDataFromStore(type: string, key: string): GraphicData {
			return this.getGraphicResponse(type, key) as GraphicData;
		},

		handleInput(params: any) {
			//TODO on filter
		},

		async fetchAllAnalize(type: ActionKeyof) {
			const isAnalizePois: Boolean = this.getPerson.isTypeAudiencePois();

			const analizeType: AnalizeType = isAnalizePois
				? AnalizeType.POIS
				: AnalizeType.GEO;

			try {
				const postBody: ConfigPostEntity =
					await this.getPerson.getPostBody();

				this.snack_color = undefined;
				this.message = this.$t("loadings.loading");

				const resultDataPois: ResultData = await this.postData({
					type: analizeType,
					body: postBody,
				});

				this.setResultData({
					type: analizeType,
					response: resultDataPois,
				});

				this.getPoisCount.resetFetch();
				let valid = true;

				if (analizeType === AnalizeType.POIS) {
					await this.fetchPoisCount();

					if (!this.getPoisCount.isValid()) valid = false;
				}

				if (!this.getPerson.isAnalized()) return;

				this.setButtonDisabled("calculate_reach", !valid);
				this.setButtonDisabled("save_pois", !valid);
				this.setButtonDisabled("geo_fencing", !valid);
				this.setButtonDisabled("store_attribution", !valid);

				// Verify if there is correct amount of POIs
				if (valid) {
					// save data to state&localStorage
					this.getPersistent.setCountry(
						this.getPerson.country_global
					);
					this.fetchGetGraphicData(isAnalizePois);
					this.snack_color = undefined;
					this.message = this.$t("loadings.loading_graphics");
					this.handleSubmitCarto();
				} else {
					// The amount exceed the limit, then send an error
					notificationService.notifyWarning(store, {
						message:
							this.getPoisCount.getTotal() === 0
								? this.$t("Persons10.errors.pois_no_data")
								: this.$t("Persons10.errors.pois_exceed", {
										maxPois: convLocaleString(
											this.getPoisCount.maxPois
										),
								  }),
					} as Notification);
				}

				this.setButtonLoading(type, false);
			} catch (error) {
				console.error(`${this.$options.name}::fetchAllAnalize`, {
					error,
				});
				this.setButtonLoading(analizeType, false);
			}
		},

		async actionNotAllowed(type: ActionKeyof) {
			this.snack_color = "orange darken-3";
			this.message = this.$t(
				"persons.v10.panelsPois.messages.one_time_action",
				{
					name: this.$t(`persons.v10.panelsPois.button.${type}`),
				}
			);
			this.setButtonLoading(type, false);
		},

		async handleAction(params: { type: ActionKeyof }) {
			const { type } = params;
			this.setButtonLoading(type, true);

			switch (type) {
				case "analize_pois":
					if (this.showReach(type)) {
						this.actionNotAllowed(type);
						break;
					}
					await this.updateFiltersClear();
					await this.fetchAllAnalize(type);
					this.clearAudienceFilters();
					break;

				case "calculate_reach":
					if (this.showReach(type)) {
						this.actionNotAllowed(type);
						break;
					}
					let result: ItemGraphic[] = await this.fetchCalculateReach(
						this.getPerson
					);
					this.setButtonLoading(type, false);
					if (!result?.length) break;
					this.setButtonDisabled("analize_audience", false);
					this.setButtonDisabled("store_attribution", true);
					this.setButtonDisabled("target_audience", false);
					break;

				case "analize_audience":
					await this.generateAnalizeAudience();
					this.setButtonLoading(type, false);
					await this.saveDataToStorage({
						items: this.itemsExpansion,
					});
					break;

				case "store_attribution":
					this.handleStoreAttributionClose(true);
					this.setButtonLoading(type, false);
					break;

				case "save_pois":
					this.handleSavePoisClose(true);
					this.setButtonLoading(type, false);
					break;

				case "target_audience":
					//TODO Target Audience
					this.handleTargetAudienceClose(true);
					this.setButtonLoading(type, false);
					break;

				case "geo_fencing":
					this.handleGeoFencingClose(true);
					this.setButtonLoading(type, false);
					break;
			}
		},

		async generateAnalizeAudience() {
			const result: ResultData = await this.fetchAnalizeAudience();
			const isStoreAttribution: Boolean =
				this.getPerson.store_attribution.showMaidUnique();

			if (!result) {
				// TODO Alert
				alert(`Ocurrio un error en la generacion de Analize Audience`);
				return;
			}

			const routeName = isStoreAttribution
				? "PersonsStoreAttribution"
				: "PersonsAudience";

			this.scrollToTop();
			return this.$router.push({
				name: routeName,
			});
		},

		async handleSubmitCarto() {
			const refs = this.$refs;
			let componentCarto = refs.pois_carto;

			await componentCarto.sendAllFilters();
		},

		setButtonDisabled(type: string, disabled: Boolean) {
			const actions: ButtonActionEntity[] = this.getPoisActions;
			const index = actions.findIndex((a) => a.type === type);
			if (index < 0) return;
			this.getPoisActions[index].setDisabled(disabled);
		},

		setButtonLoading(type: string, loading: Boolean = false) {
			const actions: ButtonActionEntity[] = this.getPoisActions;
			const index = actions.findIndex((a) => a.type === type);
			if (index < 0) return;
			this.getPoisActions[index].setLoading(loading);
		},

		disabledButton(type: PersonKey, disabled: Boolean) {
			this.getPoisCount.resetFetch();
			let expansion = this.itemsExpansion.find(
				(e: { type: PersonKey }) => e.type === type
			);
			if (!expansion) return;
			expansion.disabled = disabled;
		},

		updateButtonGeo() {
			const hasPoisType = !isNaN(this.getPerson.audience_type.id);
			this.getPoisCount.resetFetch();
			let expansion = this.itemsExpansion.find(
				(e: { type: PersonKey }) => e.type === PersonKey.GEO
			);
			if (!expansion) return;
			expansion.disabled = !hasPoisType;
		},

		updateButtonPois() {
			const hasGeo = this.getPerson.hasGeoSelected();
			this.getPoisCount.resetFetch();
			let expansion = this.itemsExpansion.find(
				(e: { type: PersonKey }) => e.type === PersonKey.POIS
			);
			if (!expansion) return;
			expansion.disabled = !hasGeo || this.getPerson.isCertainArea();
		},

		async handleStoreAttributionClose(open: Boolean = false) {
			this.getPerson.store_attribution.setLoading(open);
			this.getPerson.store_attribution.setOpen(open);
			if (open) {
				await this.getStoreAttributions();
				this.getPerson.store_attribution.setLoading(false);
			}
		},

		handleTargetAudienceClose(open: Boolean = false) {
			this.getPerson.target_audience.setOpen(open);
		},

		handleSavePoisClose(open: Boolean = false) {
			this.getPerson.save_pois.setOpen(open);
		},

		handleStoreAttributionCheck(check: Boolean = false) {
			this.getPerson.store_attribution.setCheck(check);
		},

		handleGeoFencingClose(open: Boolean = false) {
			this.getPerson.geo_fencing.setOpen(open);
		},

		async handleStoreAttributionSubmit() {
			try {
				const storeAttibution: StoreAttribution =
					this.getPerson.store_attribution;

				const dataParams = {
					country_code: this.getPerson.getCountryCode("id", "string"),
					campaign_ids: storeAttibution.selected.map((s) => s.id),
					check: Boolean(this.getPerson.store_attribution.check),
				};

				this.getPerson.store_attribution.setLoading(true);

				this.getPersistent.clearCampaigns();

				const result: ResultData = await this.fetchStoreAttribution(
					dataParams
				);

				this.getPerson.store_attribution.setResponse(result);

				// save data to state&localStorage
				this.getPersistent.setCampaigns(storeAttibution.selected);

				this.setButtonDisabled("calculate_reach", true);
				this.setButtonDisabled("analize_audience", false);

				this.setButtonDisabled("target_audience", false);

				this.getPerson.store_attribution.setLoading(false);
				this.getPerson.store_attribution.setOpen(false);
			} catch (error) {
				this.getPerson.store_attribution.setLoading(false);
				this.getPersistent.clearCampaigns();
				console.error("handleStoreAttributionSubmit", error);

				if (error instanceof Error) {
					// 👉️ err is type Error here
					console.error(error.message);
				}

				console.error("Unexpected error", error);
			}
		},

		async handleTargetAudienceSubmit(data) {
			this.exportAudience(data);
			this.handleTargetAudienceClose();
		},

		async handleSavePoisSubmit(data) {
			this.savePois(data);
			this.handleSavePoisClose();
		},

		async handleGeoFencingSubmit(data) {
			this.saveGeoFencing({
				...data,
				country_code: this.getCountry.id,
			});
			this.handleGeoFencingClose();
		},

		/**
		 * Verificar(geo|pois) y habilitar/deshabilitar boton AnalizePois
		 * @returns
		 */
		async verifyAnalizePoisButton() {
			const enableButton: Boolean =
				this.getPerson.hasPoisSelected() ||
				this.getPerson.hasPrivateSelected();

			/**
			 * Set disabled
			 */
			return this.setButtonDisabled("analize_pois", !enableButton);
		},

		// update on changed any filter
		async updateFiltersClear() {

			const isAnalized: Boolean = this.getPerson.isAnalized();

			const showCountPois: Boolean = this.showCountPois;

			if (!isAnalized && !showCountPois) return;

			this.getPoisCount.resetFetch();

			// reset buttons actions
			this.setButtonDisabled("calculate_reach", true);
			this.setButtonDisabled("store_attribution", true);
			this.setButtonDisabled("analize_audience", true);
			this.setButtonDisabled("save_pois", true);
			this.setButtonDisabled("target_audience", true);
			this.setButtonDisabled("geo_fencing", true);

			await this.getPerson.changeFilters();
			await this.clearDataToStorage();
		},
		scrollToTop() {
			document
				.querySelectorAll(".layout-main")
				.forEach((item) => item.scrollTo({ top: 100 }));
		},
		removeParamsFromURL() {
			this.$router.replace({
				name: "PersonsIndex",
			});
		},
	},
	watch: {
		"getPerson.country_global": {
			async handler(val, oldVal) {
				this.getQueryParams.setCountryCode(val?.id);
				if (isEqual(val, oldVal)) return;
				if (this.isGettingFromStored) return;
				this.handleSubmitCarto();
				await this.resetPerson({ country: val });
				await this.setDefaultAudienceType();
			},
		},
		"getPerson.audience_type": {
			handler(val) {
				const hasAudienceType = this.getPerson.hasAudienceType();
				this.updateButtonGeo();
				this.getPerson.changeAudienceType();
				this.activePanel = !hasAudienceType
					? ActivePanelTab.AUDIENCE_TYPE
					: ActivePanelTab.GEO;
				if (this.isGettingFromStored) return;
				this.initPoisActions();
			},
		},
		"getPerson.geo.selected": {
			async handler() {
				if (!this.isGettingFromStored) {
					await this.updateFiltersClear();
				}

				// habilitar/deshabilitar boton de Pois
				this.disabledButton(
					PersonKey.POIS,
					!this.getPerson.hasGeoSelected()
				);

				// verificar(geo|pois) y habilitar/deshabilitar boton AnalizePois
				this.verifyAnalizePoisButton();

				// verificar filtros e geo y pois -> actualizar activePanel
				const activePanel: ActivePanelTab | undefined =
					await this.getPerson.verifyFilters(this.activePanel);

				if (!isUndefined(activePanel)) {
					this.activePanel = activePanel;
				}
			},
			deep: true,
		},
		"getPerson.pois.selected": {
			async handler(val, oldVal) {
				if (!this.isGettingFromStored) {
					await this.updateFiltersClear();
				}

				// verificar(geo|pois) y habilitar/deshabilitar boton AnalizePois
				this.verifyAnalizePoisButton();
			},
			deep: true,
		},
		"getPerson.privates.selected": {
			async handler(val, oldVal) {
				if (!this.isGettingFromStored) {
					await this.updateFiltersClear();
				}

				// verificar(geo|pois) y habilitar/deshabilitar boton AnalizePois
				this.verifyAnalizePoisButton();
			},
			deep: true,
		},
		activePanel(data) {
			if (data > 0) {
				this.scrollToTop();
			}
		},
	},
});
