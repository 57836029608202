import { isEmpty, isEqual } from "lodash";

export const matchedKeys = {
	_poisCategory: "categories",
	_poisSubCategory: "subcategories",
	_poisMarca: "brands",
	_poisName: "names",
	_state: "states",
	_municipalities: "cities",
	_neighborhood: "neighborhoods",
	_likeNames: "likeNames",
};

export const matchedDSPCartoKeys = {
	country: "_country",
	categories: "_poisCategory",
	subcategories: "_poisSubCategory",
	brands: "_poisMarca",
	names: "_poisName",
	states: "_state",
	cities: "_municipalities",
	neighborhoods: "_neighborhood",
	likeNames: "_likeNames",
	privates: "_privatePois"
};

export function isValidWatcherProp(val: string[] | string) {
	return val !== "" && !isEmpty(val);
}

export function isEqualWatcherProp(
	val: string[] | string,
	old: string[] | string
) {
	return isEqual(val, old);
}

export function isPassedWatcherProp(
	val: string[] | string,
	old: string[] | string
) {
	return isValidWatcherProp(val) && !isEqualWatcherProp(val, old);
}
