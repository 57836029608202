import Vue from "vue";
import { mapGetters } from "vuex";
import draggable from "vuedraggable";
import { ElementData } from "@/interfaces/persons/v10/person";
import { PersonKey, TypeFilterKey } from "@/interfaces/persons/v10/types";
import ChipFilterGroup from "@/views/Admin/Persons/V10/Dependencies/Common/Chips/Filters/index.vue";

export default Vue.extend({
	name: "SelectedAndFilters",
	model: {},
	props: {
		canClose: {
			type: Boolean,
			default: true,
		},
	},
	components: { draggable, ChipFilterGroup },
	data: () => ({}),
	created() {
		this.$nextTick(() => {});
	},
	mounted() {},
	computed: {
		...mapGetters("person", ["getPerson"]),
		hasGeoSelected() {
			return this.getPerson.hasElementData(
				PersonKey.GEO,
				TypeFilterKey.SELECTED
			);
		},
		getGeoToolbars() {
			return this.getPerson.getGeoToolbars();
		},
	},
	methods: {
		getDragType(index: number) {
			return index === 0 ? TypeFilterKey.SELECTED : TypeFilterKey.AND;
		},
		onDrop(event: any) {
			var transferData: { item: ElementData; dragType: TypeFilterKey } =
				JSON.parse(event.dataTransfer.getData("transferData"));

			if (transferData.dragType === TypeFilterKey.SELECTED) {
				if (!this.getGeoToolbars.length) return;
				this.getPerson.removeFromSelected(
					transferData.item,
					PersonKey.GEO,
					transferData.dragType
				);
				this.getPerson.draggendFilter(
					PersonKey.GEO,
					TypeFilterKey.AND,
					transferData.item
				);
			}
		},
		handleRemove(event: { item: ElementData; dragType: TypeFilterKey }) {
			let { item, dragType } = event;
			this.getPerson.removeFromSelected(item, PersonKey.GEO, dragType);
		},
	},
	watch: {},
});
