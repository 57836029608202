import { ElementData } from "@/interfaces/persons/v10/person";
import Vue from "vue";
import i18n from "@/plugins/i18n";

export default Vue.extend({
	name: "PaginatedChips",
	props: {
		items: {
			type: Array,
			default: function () {
				return [];
			},
		},
		page: {
			type: Number,
			default: 0,
		},
		itemsPerPage: {
			type: Number,
			default: 10,
		},
		groupStyles: {
			type: String,
			default: "",
		},
		groupClass: {
			type: String,
			default: "",
		},
		color_set: {
			type: Object,
			default: function () {
				return {};
			},
		},
		chipClass: {
			type: String,
			default: "",
		},
		canClose: {
			type: Boolean,
			default: true,
		},
		isDraggable: {
			type: Boolean,
			default: false,
		},
		dragType: {
			type: String,
			default: "",
		},
		activePagination: {
			type: Boolean,
			default: false,
		},
		column: {
			type: Boolean,
			default: false,
		}
	},
	components: {},
	data: () => ({
		toggle_exclusive: 1,
	}),
	created() {},
	mounted() {
		this.$nextTick(async () => {});
	},
	computed: {
		currentPage: {
			get() {
				return this.page;
			},
			set(val) {
				this.$emit("page", val);
			},
		},
		totalPages() {
			return Math.ceil(this.items.length / this.itemsPerPage);
		},
		paginatedItems() {
			const start = this.currentPage * this.itemsPerPage;
			const end = start + this.itemsPerPage;
			return this.items.slice(start, end);
		},
		displayedItems() {
			return this.paginatedItems;
		},
	},
	methods: {
		changePage(pageIndex: number) {
			this.currentPage = pageIndex;
		},
		previousPage() {
			if (this.currentPage > 0) {
				this.currentPage--;
			}
		},
		nextPage() {
			if (this.currentPage < this.totalPages - 1) {
				this.currentPage++;
			}
		},
		startDrag(event: any, item: ElementData) {
			if (!this.isDraggable) return;
			var transferData = JSON.stringify({
				item,
				dragType: this.dragType,
			});

			event.dataTransfer.dropEffect = "move";
			event.dataTransfer.effectAllowed = "move";
			event.dataTransfer.setData("transferData", transferData);
			this.$emit("drag", { transferData });
		},
		handleRemove(item: any) {
			this.$emit("remove", {
				item,
				dragType: this.dragType,
			});
		},
		getNameChip(item: any){
			if(item.type == "country_global"){
				const val = `persons.v10.panelsPois.geo.${item.id}.name`;
				return i18n.t(val);
			}else{
				return item.value;
			}
		},
		getNameToolTipChip(item: any){
			if(item.type == "country_global"){
				const val = `persons.v10.panelsPois.geo.${item.id}.name`;
				return i18n.t(val);
			}else{
				return item.value || item.tooltip;
			}
		}
	},
	watch: {
		// currentPage(val) {
		// 	console.log("currentPage:watch", val);
		// },
	},
});
