import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { isEmpty, isEqual, isUndefined } from "lodash";

import { ResultElementData } from "@/interfaces/paginated";
import { ElementData } from "@/interfaces/persons/v10/person";
import {
	PersonGeoKey,
	PersonKey,
	PersonPoisKey,
	PersonPrivateKey,
} from "@/interfaces/persons/v10/types";
import {
	ExpansionItemEntity,
	ResultElementDataEntity,
	ResultType,
} from "@/models/persons/v10/Tabs/Pois/Expansions/Pois";
import { resolveResult } from "@/services/persons/v10/person-service";
import { PersonEntity } from "@/models/persons/v10/Person";
import { SelectAllProp } from "@/models/persons/v10/SelectAll/Class";

export default Vue.extend({
	name: "MixinSelectFilter",
	data: function () {
		return {
			config: {
				type: "", // geo|pois
				key: "", // filters
			},
		};
	},
	created: function () {},
	mounted: function () {},

	computed: {
		...mapGetters("person", [
			"getPerson",
			"getPostData",
			"getQueryParams",
			"getLikeAll",
		]),

		getExpansionItems(): ExpansionItemEntity[] {
			return this.expansionItems;
		},
	},

	methods: {
		...mapActions("person", ["fetchPostPaginated", "fetchGetPaginated"]),

		/**
		 * Fetch All Item Resource
		 */
		async fetchExpansionItems() {
			if (this.config.type === PersonKey.GEO) {
				this.fetchSaveResultItem(
					PersonGeoKey.STATES,
					ResultType.FILTERING
				);
				this.fetchSaveResultItem(
					PersonGeoKey.CITIES,
					ResultType.FILTERING
				);
				this.fetchSaveResultItem(
					PersonGeoKey.NEIGHBORHOODS,
					ResultType.FILTERING
				);
			}

			if (this.config.type === PersonKey.POIS) {
				this.fetchSaveResultItem(
					PersonPoisKey.CATEGORIES,
					ResultType.FILTERING
				);
				this.fetchSaveResultItem(
					PersonPoisKey.SUBCATEGORIES,
					ResultType.FILTERING
				);
				this.fetchSaveResultItem(
					PersonPoisKey.BRANDS,
					ResultType.FILTERING
				);
				this.fetchSaveResultItem(
					PersonPoisKey.NAMES,
					ResultType.FILTERING
				);
			}

			if (this.config.type === PersonKey.PRIVATE) {
				this.fetchSaveResultItem(
					PersonPrivateKey.PRIVATES,
					ResultType.FILTERING
				);
			}
		},

		/**
		 * Find expansion item by key
		 * @param key
		 * @returns
		 */
		findExpansionItem(
			key: PersonGeoKey | PersonPoisKey | PersonPrivateKey
		) {
			return (this.expansionItems as ExpansionItemEntity[]).find(
				(e) => e.key === key
			);
		},

		/**
		 * Fetch Item Resource by key
		 * @param key
		 * @returns
		 */
		async fetchItemByKey(
			key: PersonGeoKey | PersonPoisKey | PersonPrivateKey
		): Promise<ResultElementData> {
			let result: ResultElementData = new ResultElementDataEntity();

			const person: PersonEntity = this.getPerson;

			const countryGlobal: ElementData = person.country_global;

			let dataParams = {
				key,
				type: key,
				country_global: countryGlobal,
			};

			if (await this.isParamsGet(key)) {
				const pre =
					this.config.type === PersonKey.GEO
						? person.geo.pre
						: person.pois.pre;

				Object.assign(dataParams, { pre });

				result = await this.fetchGetPaginated(dataParams);
			} else {
				result = await this.fetchPostPaginated(dataParams);
			}

			return {
				...result,
				data: await resolveResult(key, result),
			};
		},

		/**
		 * Fetch Item & Save Result
		 * @param key
		 * @returns
		 */
		fetchSaveResultItem(
			key: PersonGeoKey | PersonPoisKey | PersonPrivateKey,
			resultType: ResultType
		) {
			let item: ExpansionItemEntity = this.findExpansionItem(key);

			if (isUndefined(item)) return;

			item?.setLoading(true);

			const promResult: Promise<ResultElementData> =
				this.fetchItemByKey(key);

			promResult
				.then(async (_result: ResultElementData) => {
					switch (resultType) {
						case ResultType.NEW:
							await item?.setNewToResult(_result);
							break;

						case ResultType.SEARCH:
							await item?.setNewToResult(_result);
							break;

						case ResultType.LOAD_MORE:
							await item?.loadMoreToResult(_result);
							break;

						case ResultType.FILTERING:
							await item?.setNewToResult(_result);
							break;
					}

					await this.validateLikeAll(item, resultType);

					item?.setLoading(false);
				})
				.catch((_error: any) => {
					item?.setLoading(false);
					console.error(
						`${this.$options.name}::fetchSaveResultItem`,
						{
							_error,
						}
					);
				});
		},

		async validateLikeAll(
			item: ExpansionItemEntity,
			resultType: ResultType
		) {
			let person: PersonEntity = this.getPerson;

			let isSelectedAll: Boolean =
				person.select_all[item.type][item.key].total.checked;

			let selectedData: ElementData[] =
				person[this.config.type]?.selected[item.key] || [];

			let isSelectedShown: Boolean =
				person.select_all[item.type][item.key].shown.checked;

			if (isSelectedShown) {
				if (item.resultData.data.length !== selectedData.length) {
					person.select_all.clearChecked(item.type as any, item.key);
				}
			}

			if (isSelectedAll && resultType === ResultType.LOAD_MORE) {
				await this.toggleSelectAll(item, isSelectedAll);
			}
		},

		async toggleSelectAll(item: ExpansionItemEntity, all: Boolean) {
			const items: ElementData[] = all ? item.resultData.data : [];
			await this.getPerson.toggleSelectAll(
				item.type,
				"selected",
				item.key,
				items
			);
		},

		async handleUpdate(params: {
			key: any;
			item: any;
			ariaChecked: Boolean;
		}) {
			let { key, item, ariaChecked } = params;

			await this.getPerson.updateFilter(
				this.config.type,
				this.config.key,
				key,
				item
			);

			let _item: ExpansionItemEntity = this.findExpansionItem(key);

			const omitKeys: SelectAllProp[] = [
				SelectAllProp.NAMES,
				SelectAllProp.NEIGHBORHOODS,
				SelectAllProp.PRIVATE,
			];

			if (!omitKeys.includes(key)) {
				await this.getPerson.togglePreFilter({
					key: this.config.type,
					filter: key,
					toggle: ariaChecked,
					element: item,
				});
			} else if (!ariaChecked) {
				/**
				 * si el aria {ariaChecked} es false actualizar el checked de total -> {select_all}
				 */
				this.getPerson.select_all.clearCheck(
					this.config.type,
					_item.key,
					"total"
				);
			}

			// if (
			// 	this.getPerson.select_all[this.config.type][_item.key].total
			// 		.checked
			// )
			// 	return;

			//await this.verifySelectedInResult(key);
		},

		async handleSearch(params: {
			action: ResultType;
			key: PersonGeoKey | PersonPoisKey | PersonPrivateKey;
			current_page: number;
		}) {
			let { key } = params;
			this.fetchSaveResultItem(key, params.action);
		},

		async handleLoadMore(params: {
			key: PersonGeoKey | PersonPoisKey | PersonPrivateKey;
			current_page: number;
		}) {
			let { key } = params;

			this.fetchSaveResultItem(key, ResultType.LOAD_MORE);

			if (await this.isParamsGet(key)) {
				this.getQueryParams.setSearching(key, false);
			} else {
				this.getPostData.setSearching(key, false);
			}
		},

		async isParamsGet(key: PersonPoisKey | PersonPrivateKey) {
			return (
				this.config.type === PersonKey.GEO ||
				key === PersonPrivateKey.PRIVATES
			);
		},

		async filterData(
			key: PersonPoisKey | PersonPoisKey | PersonPrivateKey
		): Promise<ElementData[]> {
			return this.getPerson[this.config.type]?.selected[key] || [];
		},

		async compareElements(elements: ElementData[], compare: ElementData[]) {
			return elements.every((e) => compare.some((c) => c.id === e.id));
		},

		async verifySelectedInResult(key: string) {
			let _item: ExpansionItemEntity = this.findExpansionItem(key);

			if (!_item.key) return;

			const elements: ElementData[] = _item.resultData.data;

			const selectedData: ElementData[] = await this.filterData(key);

			const resultIncludesSelected: Boolean = await this.compareElements(
				elements,
				selectedData
			);

			this.getPerson.select_all.setChecked(
				this.config.type,
				_item.key,
				"shown",
				resultIncludesSelected && !isEmpty(elements)
			);
		},
	},
	watch: {
		async isActive(val, old) {
			if (isUndefined(val) || !Boolean(val)) return;

			for (
				let index = 0;
				index < this.getExpansionItems.length;
				index++
			) {
				await this.getExpansionItems[index].setCurrentPage(1);

				const isGet: Boolean = await this.isParamsGet();

				if (isGet) {
					this.getQueryParams.setCurrentPage(
						this.getExpansionItems[index].key,
						1
					);
				} else {
					this.getPostData.setCurrentPage(
						this.getExpansionItems[index].key,
						1
					);
				}
			}

			await this.fetchExpansionItems();
		},
		"getPerson.country_global": {
			async handler(val, oldVal) {
				if (isEqual(val, oldVal)) return;
				await this.fetchExpansionItems();
			},
			deep: true,
		},

		/**
		 * GEO pre
		 */
		"getPerson.geo.pre.states"() {
			this.fetchSaveResultItem(PersonGeoKey.CITIES, ResultType.FILTERING);
			this.fetchSaveResultItem(
				PersonGeoKey.NEIGHBORHOODS,
				ResultType.FILTERING
			);
		},

		"getPerson.geo.pre.cities"() {
			this.fetchSaveResultItem(
				PersonGeoKey.NEIGHBORHOODS,
				ResultType.FILTERING
			);
		},

		/**
		 * POIS pre
		 */

		"getPerson.pois.pre.categories"() {
			this.fetchSaveResultItem(
				PersonPoisKey.SUBCATEGORIES,
				ResultType.FILTERING
			);
			this.fetchSaveResultItem(
				PersonPoisKey.BRANDS,
				ResultType.FILTERING
			);
			this.fetchSaveResultItem(PersonPoisKey.NAMES, ResultType.FILTERING);
		},

		"getPerson.pois.pre.subcategories"() {
			this.fetchSaveResultItem(
				PersonPoisKey.BRANDS,
				ResultType.FILTERING
			);
			this.fetchSaveResultItem(PersonPoisKey.NAMES, ResultType.FILTERING);
		},

		"getPerson.pois.pre.brands"() {
			this.fetchSaveResultItem(PersonPoisKey.NAMES, ResultType.FILTERING);
		},
	},
});
