import {
	DataFilter,
	DataType,
	PostMessageEvent,
} from "@/interfaces/persons/v10/carto";
import { ElementData } from "@/interfaces/persons/v10/person";
import { GeoAllResponse } from "@/interfaces/persons/v10/query/geo";
import { Mode } from "@/interfaces/persons/v10/query/global";
import { isUrl } from "@/services/rule-services";
import { getHash, sleep } from "@/utils/convert";
import { isEmpty, isUndefined } from "lodash";
import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { matchedDSPCartoKeys, matchedKeys } from "./utils";

export default Vue.extend({
	name: "CartoPois",
	model: {},
	props: {
		reference: {
			type: String,
			default: "_pois_carto",
		},
		styles: {
			type: Object,
			default: function () {
				return {
					width: "100%",
					height: "800px",
					border: "none",
					margin: "0px auto",
				};
			},
		},
		loading: {
			type: Boolean,
			default: false,
		},
	},
	components: {},
	data: () => ({
		hash: "",
		isLoaded: false,
	}),
	created() {
		// this.$nextTick(async () => {
		// 	this.addListenerMessage();
		// });
	},
	beforeDestroy() {},
	async mounted() {
		this.$nextTick(async () => {
			this.hash = await getHash();
			if(!this.hasURL) {
				this.isLoaded = true;
			}
		});
	},
	computed: {
		...mapGetters("person", ["getPerson"]),
		hasURL() {
			if (!this.hash) return false;
			return isUrl(process.env.VUE_APP_CARTO_URL);
		},
		getURL() {
			return `${this.getPerson.getEndpoint()}/${this.$i18n.locale || "en"}`;
		},
		getEndpointKey() {
			return this.getPerson.getEndpointKey();
		},
		hasCountry() {
			return this.getPerson.hasCountry();
		},
		getCountry() {
			if (!this.getPerson.hasCountry()) return [];
			return this.getPerson.getCountryCode("id", "array");
		},
	},
	methods: {
		...mapActions("person", ["fetchDataPaginated", "fetchPostPaginated"]),
		getFilters() {
			return this.getPerson.getFiltersForCarto();
		},

		async addListenerMessage() {
			window.addEventListener("message", this.captureMessage, false);
		},

		async captureMessage(event: MessageEvent) {
			const { origin, data }: { origin: string; data: PostMessageEvent } =
				event;

			if (origin !== process.env.VUE_APP_CARTO_URL) return;

			if (data.type !== DataType.UPDATE) return;

			// Scroll to top
			document
				.querySelectorAll(".layout-main")
				.forEach((item) => item.scrollTo({ top: 0, behavior: 'smooth' }));

			await Promise.all(Object.entries(data.data).map(([key, value]) => {
				if (!isUndefined(value)) {
					return this.updateDataFilters({ key, value });
				}
			}))

			await sleep(500);
			this.$emit('onSync');
		},

		async updateDataFilters(params: { key: string; value: string[] }) {
			const updateData = {
				key: matchedKeys[params.key],
				value: params.value,
			};

			const isGeo = ["states", "cities", "neighborhoods"].includes(
				updateData.key
			);

			if (isGeo) {
				const response: GeoAllResponse[] = await this.fetchResource(
					updateData.key
				);

				let elements: ElementData[] = [];

				for (const filterName of updateData.value) {
					const findedFilter = response.find((r) =>
						r.nombre.includes(filterName)
					);
					elements.push({
						id: findedFilter?.codigo,
						value: findedFilter?.nombre,
						count: undefined,
					} as ElementData);
				}

				await this.getPerson.setNewFilters(
					"geo",
					"selected",
					updateData.key,
					elements
				);
			} else {
				console.log(`POIS ${updateData.key}`);

				if (!this.getPerson.hasAudienceType()) return;

				if (!this.getPerson.hasGeoSelected()) return;

				//TODO agregar pois
				const response: GeoAllResponse[] = await this.fetchPostResource(
					updateData.key
				);

				let elements: ElementData[] = [];

				for (const filterName of updateData.value) {
					const findedFilter = response.find((r) =>
						r.nombre.includes(filterName)
					);
					elements.push({
						id: findedFilter?.codigo,
						value: findedFilter?.nombre,
						count: undefined,
					} as ElementData);
				}

				await this.getPerson.setNewFilters(
					"pois",
					"selected",
					updateData.key,
					elements
				);
			}
		},

		async fetchResource(key: string): Promise<GeoAllResponse[]> {
			return await this.fetchDataPaginated({
				type: key,
				filters: this.getPerson.getConfigModeAll(),
			});
		},

		async fetchPostResource(key: string): Promise<any[]> {
			return await this.fetchPostPaginated({
				type: key,
				mode: Mode.ALL,
			});
		},

		async initializeCountry() {
			await this.$nextTick();
			const country: number[] = this.getPerson.getCountryCode(
				"id",
				"array"
			);
			if (isEmpty(country)) return;
			await this.syncFilter("country", country, undefined);
			await this.runSubmit();
		},

		/**
		 * Run Submit PostMessage
		 */
		async runSubmit() {
			return await this.postMessage({ type: "SUBMIT" } as DataFilter);
		},

		/**
		 * Iframe postMessage
		 * @param dataFilter
		 */
		async postMessage(dataFilter: DataFilter) {
			const iframe = window.frames[`${this.reference}`];
			return iframe.contentWindow.postMessage(
				dataFilter,
				process.env.VUE_APP_CARTO_URL
			);
		},

		syncFilter(
			type: string,
			val: string[] | number[],
			old?: string[] | number[]
		) {
			const params = {
				type: "UPDATE",
				key: matchedDSPCartoKeys[type],
				value: val,
			} as DataFilter;

			if (!this.isLoaded) return Promise.resolve(undefined);
			// if (!isPassedWatcherProp(val, old)) return;
			return Promise.resolve(this.postMessage(params as DataFilter));
		},

		async sendAllFilters() {
			const country: number[] = this.getCountry;
			await this.syncFilter("country", country, undefined);
			// geo
			await this.sendFilter("geo", "states");
			await this.sendFilter("geo", "cities");
			await this.sendFilter("geo", "neighborhoods");

			// pois
			await this.sendFilter("pois", "categories");
			await this.sendFilter("pois", "subcategories");
			await this.sendFilter("pois", "brands");
			await this.sendFilter("pois", "names");
			await this.sendFilter("privates", "privates");

			setTimeout(() => {
				this.runSubmit();
			}, 500);
		},

		async handleLoad() {
			this.isLoaded = true;
			await sleep(100);
			await this.sendAllFilters();
			await this.addListenerMessage();
			//this.$emit("load");
		},

		async sendFilter(type: "geo" | "pois" | "privates", key: string) {
			const elements: ElementData[] = this.getPerson[type].selected[key];
			const filters: (string|number)[] = elements.map((f) => {
				if(["privates"].includes(key)) return f.id; 
				return f.value;
			});
			if(type === "pois" && key === "names") {
				const { term, checked } = this.getPerson.select_all[type][key].total;

				this.syncFilter("likeNames", checked ? [term] : [])
			}
			await this.syncFilter(key, filters);
		},
	},
	// watch: {
	// 	async "getPerson.country_global.value"(val: any, old: any) {
	// 		this.hash = await getHash();
	// 		this.isLoaded = false;
	// 	},
	// },
});
