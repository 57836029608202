import Vue from "vue";
// @ts-ignore
import CreateTabs from "@/views/Admin/Commons/Tabs.vue";
// @ts-ignore
import i18n from "@/plugins/i18n";
// @ts-ignore
import TabPois from "@/views/Admin/Persons/V10/Dependencies/Tabs/Pois/index.vue";
import TabAudience from "@/views/Admin/Persons/V10/Dependencies/Tabs/Audience/index.vue";

export default Vue.extend({
	name: "PersonsIndex",
	props: {},
	components: {
		CreateTabs,
		TabPois,
		TabAudience
	},
	data: () => ({
		currentTab: 0,
		filters: {},
	}),
	created() {},
	mounted() {},
	computed: {
		getTabItems() {
			return [
				{
					key: 0,
					tab: i18n.t("persons.tabs.pois"),
					disabled: !this.isTabIndex(0),
				},
				{
					key: 1,
					tab: i18n.t("persons.tabs.audience"),
					disabled: !this.isTabIndex(1),
				},
				{
					key: 2,
					tab: i18n.t("persons.tabs.store-attribution"),
					disabled: !this.isTabIndex(2),
				},
			];
		},
	},
	methods: {
		isCurrentTabSelected(item: { key: number }, index: number): Boolean {
			return item.key === index;
		},

		tabIndex(index: number = 0) {
			this.currentTab = index;
		},

		isTabIndex(index: number = 0) {
			return this.currentTab === index;
		},

		handleChangeTab(tab: number) {
			this.updateTab(tab);
		},

		updateTab(tab: number = 0) {
			this.tabIndex(tab);
			window.scrollTo(0, 0);
		},
	},
	watch: {},
});
