import Vue from "vue";

import ExpansionItem from "@/views/Admin/Persons/V10/Dependencies/Select/Commons/ExpansionItem/index.vue";
import {
	ExpansionItemEntity,
	getGeoExpansions,
} from "@/models/persons/v10/Tabs/Pois/Expansions/Pois";
import {
	PersonGeoKey,
	PersonKey,
	TypeFilterKey,
} from "@/interfaces/persons/v10/types";
import SelectFilter from "../../Mixins/SelectFilter";

export default Vue.extend({
	name: "SelectGeo",
	mixins: [SelectFilter],
	model: {},
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
	},
	components: { ExpansionItem },
	data: () => ({
		expansionItems: [] as ExpansionItemEntity[],
		config: {
			type: PersonKey.GEO,
			key: TypeFilterKey.SELECTED,
		},
	}),
	created() {
		this.$nextTick(async () => {
			/**
			 * Load data structure
			 */
			this.expansionItems = await getGeoExpansions();

			/**
			 * Load the initial data for each item
			 */
			this.fetchExpansionItems();
		});
	},
	methods: {},
	watch: {
		"getPerson.geo.selected.states"() {
			this.verifySelectedInResult(PersonGeoKey.STATES);
		},

		"getPerson.geo.selected.cities"() {
			this.verifySelectedInResult(PersonGeoKey.CITIES);
		},

		"getPerson.geo.selected.neighborhoods"() {
			this.verifySelectedInResult(PersonGeoKey.NEIGHBORHOODS);
		},
	},
});
