import { ElementData } from "@/interfaces/persons/v10/person";
import Vue from "vue";
import draggable from "vuedraggable";

export default Vue.extend({
	name: "ChipFilterGroup",
	model: {},
	props: {
		canClose: {
			type: Boolean,
			default: true,
		},
		color_set: {
			type: Object,
			default: {}
		},
		filters: {
			type: Array,
			default: function () {
				return [];
			},
		},
		chipClass: {
			type: String,
			default: "",
		},
		groupStyles: {
			type: String,
			default: "",
		},
		groupClass: {
			type: String,
			default: "",
		},
		isDraggable: {
			type: Boolean,
			default: false,
		},
		dragType: {
			type: String,
			default: "",
		},
		column: {
			type: Boolean,
			default: false,
		}
	},
	components: { draggable },
	data: () => ({}),
	created() {
		this.$nextTick(() => {});
	},
	mounted() {},
	computed: {},
	methods: {
		startDrag(event: any, item: ElementData) {
			if (!this.isDraggable) return;
			var transferData = JSON.stringify({
				item,
				dragType: this.dragType,
			});

			event.dataTransfer.dropEffect = "move";
			event.dataTransfer.effectAllowed = "move";
			event.dataTransfer.setData("transferData", transferData);
			this.$emit("drag", { transferData });
		},
		handleRemove(item: any) {
			this.$emit("remove", {
				item,
				dragType: this.dragType,
			});
		},
	},
	watch: {},
});
