import i18n from "@/plugins/i18n";

export const graphicDataItems = {
	geo: [
		getGraphicData("pois", "geo", "states"),
		getGraphicData("pois", "geo", "cities"),
		getGraphicData("pois", "geo", "neighborhoods"),
	],
	pois: [
		getGraphicData("pois", "pois", "pois"),
		getGraphicData("pois", "pois", "categories"),
		getGraphicData("pois", "pois", "subcategories"),
		getGraphicData("pois", "pois", "brands"),
		getGraphicData("pois", "pois", "states"),
		getGraphicData("pois", "pois", "cities"),
		getGraphicData("pois", "pois", "neighborhoods"),
	],
};
/**
 * Graphic Data
 * @param tab pois|audience
 * @param type geo|pois
 * @param key states|cities|...
 * @returns
 */
export function getGraphicData(tab: string, type: string, key: string) {
	const infoDataName = `graphics.${tab}.${type}.${key}`;
	return {
		key: key,
		infoData: {
			id: `${type}_${key}`,
			name: i18n.t(infoDataName),
		},
	};
}
