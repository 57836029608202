import Vue from "vue";

import ExpansionItem from "@/views/Admin/Persons/V10/Dependencies/Select/Commons/ExpansionItem/index.vue";
import {
	ExpansionItemEntity,
	getPrivateExpansions,
} from "@/models/persons/v10/Tabs/Pois/Expansions/Pois";
import {
	PersonKey,
	PersonPrivateKey,
	TypeFilterKey,
} from "@/interfaces/persons/v10/types";
import SelectFilter from "../../Mixins/SelectFilter";

export default Vue.extend({
	name: "SelectPrivate",
	mixins: [SelectFilter],
	model: {},
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
	},
	components: { ExpansionItem },
	data: () => ({
		expansionItems: [] as ExpansionItemEntity[],
		config: {
			type: PersonKey.PRIVATE,
			key: TypeFilterKey.SELECTED,
		},
	}),
	created() {
		this.$nextTick(async () => {
			/**
			 * Load data structure
			 */
			this.expansionItems = await getPrivateExpansions();

			/**
			 * Load the initial data for each item
			 */
			this.fetchExpansionItems();
		});
	},
	methods: {},
	watch: {
		"getPerson.privates.selected.privates"() {
			this.verifySelectedInResult(PersonPrivateKey.PRIVATES);
		},
	},
});
