import Vue from "vue";
import CardTextField from "@/components/Content/CardTextField.vue";
import { mapGetters } from "vuex";
import { debounce, isEmpty } from "lodash";
import { ElementData } from "@/interfaces/persons/v10/person";
import { convLocaleString, sleep } from "@/utils/convert";
import FilterCheckboxSkeleton from "@/components/Commons/Skeletons/FilterCheckbox/index.vue";
import {
	ExpansionItemEntity,
	ResultType,
} from "@/models/persons/v10/Tabs/Pois/Expansions/Pois";
import {
	PersonGeoKey,
	PersonKey,
	PersonPoisKey,
	PersonPrivateKey,
	SelectedType,
	TotalType,
	TypeFilterKey,
} from "@/interfaces/persons/v10/types";
import { ResultElementData } from "@/interfaces/paginated";
import AppSelectedAll from "@/views/Admin/Persons/V10/Dependencies/Select/Commons/All/index.vue";
import {
	hideViewClearFilters,
	shownMaxSelection,
} from "@/models/persons/v10/Person";
import { colorSet } from "@/utils/persons/v10/helpers";
import { SelectedDataEntity } from "@/models/persons/v10/Implements";
import { getDelaySelection } from "@/interfaces/persons/v10/query/global";

const TooltipCheckedImage = require("@/assets/images/tooltip_personas_checked.jpg");
const TooltipUnheckedImage = require("@/assets/images/tooltip_personas_unchecked.jpg");
const TooltipFilterImage = require("@/assets/images/tooltip_personas_filter.jpg");

export default Vue.extend({
	name: "ExpansionItem",
	props: {
		item: {
			type: Object,
			default: function () {
				return {};
			},
		},
		type: {
			type: String,
		},
	},
	components: { CardTextField, FilterCheckboxSkeleton, AppSelectedAll },
	data: () => ({}),
	created() {},
	mounted() {
		this.$nextTick(async () => {});
	},
	computed: {
		...mapGetters("person", [
			"getPerson",
			"getLikeAll",
			"getQueryParams",
			"getPostData",
		]),

		getTranslatedTitle() {
			if(this.type === PersonKey.GEO) {
				let country_code = this.getPerson.country_global.id;
				let translation = `persons.v10.panelsPois.geo.${country_code}.${this.item.key}`;
				if(this.$te(translation)) return this.$t(translation)
			}
			return this.item.title;
		},

		getTooltipCheckedImage() {
			return TooltipCheckedImage;
		},

		getTooltipUncheckedImage() {
			return TooltipUnheckedImage;
		},

		getTooltipFilterImage() {
			return TooltipFilterImage;
		},

		getColor() {
			return colorSet[this.item.key] || "secondary";
		},

		moduleAllTotal: {
			get() {
				return this.getPerson.select_all[this.type][this.item.key][
					TotalType.TOTAL
				];
			},
			set(val) {},
		},

		moduleAllShown: {
			get() {
				return this.getPerson.select_all[this.type][this.item.key][
					TotalType.SHOWN
				];
			},
			set(val) {},
		},

		selectedData: {
			get(): ElementData[] {
				return this.getPerson[this.type]?.selected[this.item.key] || [];
			},
			async set(val: ElementData[]) {},
		},

		preData: {
			get(): ElementData[] {
				return this.getPerson[this.type]?.pre[this.item.key] || [];
			},
			set(val) {},
		},

		andData: {
			get(): ElementData[] {
				return this.getPerson[this.type]?.and[this.item.key] || [];
			},
			set(val) {},
		},

		mergedData: {
			get(): ElementData[] {
				if (this.type === PersonKey.POIS)
					return this.selectedData as ElementData[];
				return [
					...new Set([...this.selectedData, ...this.andData]),
				] as ElementData[];
			},
			set(val) {},
		},

		getResultOptions() {
			return this.item.resultData.data || [];
		},

		hasItem(): Boolean {
			return this.item?.resultData?.data?.length > 0;
		},

		enableLoadMore(): Boolean {
			return (
				this.item?.resultData?.total >
				this.item?.resultData?.data.length
			);
		},

		isSelectedAll(): Boolean {
			return (
				this.item?.resultData?.total ===
				this.item?.resultData?.data.length
			);
		},

		getTotal() {
			const resultData: ResultElementData = this.item.resultData;
			return resultData.total;
		},

		getShown() {
			return this.item?.resultData?.data.length ?? 0;
		},

		hasTotal() {
			return this.getTotal > 0;
		},

		showCheckBox() {
			return (
				this.item.key === PersonPoisKey.NAMES &&
				this.moduleAllTotal.term
			);
		},

		showClearFilters() {
			return !hideViewClearFilters.includes(this.item.key);
		},

		getSelectingText() {
			let message = `persons.v10.checkbox.${
				this.mergedData.length ? "checking" : "unchecking"
			}`;
			return this.$t(message);
		},
	},
	methods: {
		handleClick(type: TotalType) {
			const checked: Boolean =
				type === TotalType.TOTAL
					? this.moduleAllTotal.checked
					: this.moduleAllShown.checked;

			this.makeSelection(type, checked);
		},

		getLabel(translateText: string, type: TotalType) {
			let params = {};

			if (type === TotalType.TOTAL) {
				Object.assign(params, { term: this.moduleAllTotal.term });
			}

			if (type === TotalType.SHOWN) {
				Object.assign(params, { total: shownMaxSelection });
			}

			return this.$t(translateText, params);
		},
		getTotalType() {
			return TotalType;
		},
		async toggleSelectAll(all: Boolean) {
			/**
			 * Filtros seleccionados (checkeados)
			 */
			const selectedData: ElementData[] = this.selectedData;

			/**
			 * Items disponibles (resultados paginados)
			 */
			const itemResultData: ElementData[] =
				this.item.resultData.data ?? [];

			let elements: ElementData[] = [];

			/**
			 * Si {all} === true Obtener los filtros seleccionados
			 * que no esten en los resultados paginados
			 *
			 * else: Obtener los filtros seleccionados que esten
			 * en los resultados paginados
			 */
			elements = itemResultData.filter((i) => {
				return all
					? !selectedData.some((r) => i.id === r.id)
					: selectedData.some((r) => i.id === r.id);
			});

			await this.getPerson.toggleSelectedAll({
				key: this.type,
				type: TypeFilterKey.SELECTED,
				all: all,
				filter: this.item.key,
				elements: elements,
			});

			await this.getPerson.toggleSelectedAll({
				key: this.type,
				type: TypeFilterKey.PRE,
				all: all,
				filter: this.item.key,
				elements: elements,
			});
		},
		isChecked(item: ElementData) {
			return (
				this.selectedData.filter((f: ElementData) => f.id === item.id)
					.length > 0
			);
		},
		getOptionValue(option: ElementData) {
			return option as ElementData;
		},
		hasInFilterData(item: ElementData) {
			const selectedData: ElementData[] = this.selectedData;
			return selectedData.some((f) => f.id === item.id);
		},
		getInFilterData(item: ElementData) {
			const selectedData: ElementData[] = this.selectedData;
			return selectedData.find((f) => f.id === item.id);
		},
		async isParamsGet() {
			return (
				this.type === PersonKey.GEO ||
				this.item.key === PersonPrivateKey.PRIVATES
			);
		},
		async addToPre(key: string, item: ElementData) {
			if (
				key === PersonGeoKey.NEIGHBORHOODS ||
				key === PersonPrivateKey.PRIVATES ||
				key === PersonPoisKey.NAMES
			)
				return;

			this.getPerson.updatePreFilter(this.type, "pre", key, item);

			let _item: ExpansionItemEntity = this.item;

			const isGet: Boolean = await this.isParamsGet();

			isGet
				? await this.getQueryParams.resetFilters(_item.key)
				: await this.getPostData.resetFilters(_item.key);

			const currentPage: number = isGet
				? await this.getQueryParams.getCurrentPage(_item.key)
				: await this.getPostData.getCurrentPage(_item.key);

			_item.setCurrentPage(currentPage);

			this.$emit("fetch", {
				current_page: currentPage,
				key: this.item.key,
			});
		},

		getClassPre(item: ElementData) {
			const pre: ElementData[] = this.preData;
			return !!pre.find((p) => p.id === item.id)?.id;
		},

		getClassChecked(item: ElementData) {
			const pre: ElementData[] = this.mergedData;
			return !!pre.find((p) => p.id === item.id)?.id;
		},

		getTextClass(item: ElementData) {
			let addClass: string = SelectedType.UNSELECTED;

			if (this.getClassChecked(item)) {
				addClass = SelectedType.CHECKED;
			}

			if (this.getClassPre(item)) {
				addClass = SelectedType.SELECTED;
			}

			return addClass;
		},
		async handleSelectItem(key: string, item: ElementData) {
			const refs = this.$refs;
			const elementRef = refs[item.id][0];
			const getValue: SelectedDataEntity = elementRef.value;

			this.$emit("update", {
				key,
				item,
				ariaChecked: getValue.checked,
			});
		},
		async handleLoadMore() {
			let item: ExpansionItemEntity = this.item;

			const isGet: Boolean = await this.isParamsGet();

			isGet
				? await this.getQueryParams.increasePage(item.key)
				: await this.getPostData.increasePage(item.key);

			const currentPage: number = isGet
				? await this.getQueryParams.getCurrentPage(item.key)
				: await this.getPostData.getCurrentPage(item.key);

			this.$emit("more", {
				current_page: currentPage,
				key: item.key,
			});
		},

		/**
		 * Limpia el campo search
		 */
		async handleRemoveSearch() {
			let item: ExpansionItemEntity = this.item;
			this.getPerson.select_all.clearSearch(
				item.type,
				item.key,
				TotalType.TOTAL
			);
		},

		/**
		 * Limpia todos los filtros aplicados
		 * : pre
		 */
		async handleRemoveFilters() {
			let item: ExpansionItemEntity = this.item;

			item.setCurrentPage(1);

			const isGet: Boolean = await this.isParamsGet();
			isGet
				? await this.getQueryParams.resetFilters(item.key)
				: await this.getPostData.resetFilters(item.key);

			isGet
				? await this.getQueryParams.setCurrentPage(item.key, 1)
				: await this.getPostData.setCurrentPage(item.key, 1);

			this.getPerson.clearFilter(this.type, "pre", item.key);
		},

		/**
		 * Limpia todos los filtros seleccionados
		 * : selected
		 */
		async handleRemoveSelections() {
			let item: ExpansionItemEntity = this.item;
			item.setCurrentPage(1);

			const isGet: Boolean = await this.isParamsGet();
			isGet
				? await this.getQueryParams.resetFilters(item.key)
				: await this.getPostData.resetFilters(item.key);

			isGet
				? await this.getQueryParams.setCurrentPage(item.key, 1)
				: await this.getPostData.setCurrentPage(item.key, 1);

			this.getPerson.clearFilter(this.type, "selected", item.key);

			this.getPerson.select_all.clearChecked(item.type, item.key);

			await this.updateSelectedAllMap(false);
		},

		convert(value: number, parenthesis: Boolean = true): string {
			if (parenthesis) return `(${convLocaleString(value)})`;
			return `${convLocaleString(value)}`;
		},

		async makeSelection(type: TotalType, selectedAll: Boolean) {
			let item: ExpansionItemEntity = this.item;

			const DELAY = getDelaySelection(item.getCount());

			if (DELAY > 0) {
				item.setSelecting(true);
			}

			this.getPerson.select_all.updateChecked(
				item.type,
				item.key,
				type,
				selectedAll
			);

			await this.getPerson.toggleSelectedAll({
				key: this.type,
				type: TypeFilterKey.SELECTED,
				all: selectedAll,
				filter: this.item.key,
				elements: this.item.resultData.data ?? [],
			});

			await this.updateSelectedAllMap(selectedAll);

			setTimeout(async () => {
				if (DELAY > 0) item.setSelecting(false);
			}, DELAY);
		},

		async updateSelectedAllMap(checked: Boolean = false) {
			this.item.resultData.data.map((d: SelectedDataEntity) =>
				d.setChecked(checked)
			);
		},

		async makeFilter(term: string = "") {
			//if (term.length < 2) return;

			const isGet: Boolean = await this.isParamsGet();

			isGet
				? await this.getQueryParams.setSearch(this.item.key, term)
				: await this.getPostData.setSearch(this.item.key, term);

			const currentPage: number = isGet
				? this.getQueryParams.getCurrentPage(this.item.key)
				: this.getPostData.getCurrentPage(this.item.key);

			this.item.setCurrentPage(currentPage);

			this.$emit("search", {
				current_page: currentPage,
				action: isEmpty(term)
					? ResultType.FILTERING
					: ResultType.SEARCH,
				key: this.item.key,
			});
		},

		async verifyCheckedInMerge(items: SelectedDataEntity[]) {
			items.map((d: SelectedDataEntity) => {
				let merged: SelectedDataEntity[] = this.mergedData;
				const hasItem = merged.some((m) => m.id === d.id);
				if (hasItem) d.setChecked(true);
			});
		},
	},
	watch: {
		"moduleAllTotal.term": debounce(async function (val: string | null) {
			const term: string = val?.trim() ?? "";
			await this.makeFilter(term);
		}, 750),
		mergedData: {
			handler(val: SelectedDataEntity[]) {
				let resultData: SelectedDataEntity[] =
					this.item.resultData.data;

				let itemDiff = resultData.filter((v) => !val.includes(v));

				if (!isEmpty(itemDiff)) {
					itemDiff.map((i) => i.setChecked(false));
				}
			},
			deep: true,
			immediate: true,
		},
		"item.resultData.data": {
			handler(val: SelectedDataEntity[]) {
				this.verifyCheckedInMerge(val);
			},
			deep: true,
			immediate: true,
		},
	},
});
