import Vue from "vue";
import { mapActions, mapGetters } from "vuex";
import { PersonKey } from "@/interfaces/persons/v10/types";
import { ElementData } from "@/interfaces/persons/v10/person";
import FilterCheckboxSkeleton from "@/components/Commons/Skeletons/FilterCheckbox/index.vue";

export default Vue.extend({
	name: "SelectRadioAudienceType",
	model: {},
	props: {
		isActive: {
			type: Boolean,
			default: false,
		},
	},
	components: { FilterCheckboxSkeleton },
	data: () => ({}),
	created() {
		this.$nextTick(async () => {});
	},
	mounted() {},
	computed: {
		...mapGetters("person", ["getPerson", "getResource"]),
		selectedData: {
			get(): ElementData {
				return this.getPerson?.getByKey(PersonKey.AUDIENCE_TYPE);
			},
			set(val: ElementData) {
				this.getPerson.setByKey(PersonKey.AUDIENCE_TYPE, val);
			},
		},
		getItems() {
			return this.getResource({ key: PersonKey.AUDIENCE_TYPE }) || [];
		},
	},
	methods: {
		...mapActions("person", ["fetchData", "setResource"]),
	},
	watch: {
		// isActive(val, old) {
		// 	console.log(`${this.$options.name}::isActive`, { val, old });
		// },
		"getPerson.country_global": {
			async handler(val, oldVal) {},
		},
	},
});
